// profile/SnackProfileUserWorkoutsCard.jsx
import React from "react";
import { FaDumbbell, FaRedo, FaLayerGroup, FaInfoCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackProfileUserWorkoutsCard.css"; // Archivo CSS para estilos personalizados
import { useStatusBoard } from "../../../../utensils/StatusBoardContext"; // Importar el hook

const SnackProfileUserWorkoutsCard = ({ exercise }) => {
  const { setSelectedExercise } = useStatusBoard(); // Extraer la función para actualizar el ejercicio seleccionado

  // Función para seleccionar la imagen de acuerdo al grupo muscular
  const getImageForGroup = (grupoMuscular) => {
    switch (grupoMuscular) {
      case "Bíceps":
        return require("../../../../ingredients/multimedia/images/exercises/biceps-imagen.png");
      case "Hombro":
        return require("../../../../ingredients/multimedia/images/exercises/hombro-imagen.png");
      case "Cuádriceps":
        return require("../../../../ingredients/multimedia/images/exercises/cuadriceps-imagen.png");
      case "Pecho":
        return require("../../../../ingredients/multimedia/images/exercises/pecho-imagen.png");
      case "Glúteos":
        return require("../../../../ingredients/multimedia/images/exercises/gluteos-imagen.png");
      case "Isquiotibiales":
        return require("../../../../ingredients/multimedia/images/exercises/Isquiotibiales-imagen.png");
      case "Espalda":
        return require("../../../../ingredients/multimedia/images/exercises/espalda-imagen.png");
      case "Tríceps":
        return require("../../../../ingredients/multimedia/images/exercises/triceps-imagen.png");
      case "Lumbar":
        return require("../../../../ingredients/multimedia/images/exercises/lumbar-imagen.png");
      case "Abdominales":
        return require("../../../../ingredients/multimedia/images/exercises/abdominales-imagen.png");
      case "Aductor/Abductor":
        return require("../../../../ingredients/multimedia/images/exercises/aductor-abductor.png");
      case "Pantorrilla":
        return require("../../../../ingredients/multimedia/images/exercises/pantorrilla-imagen.png");
      default:
        return require("../../../../ingredients/multimedia/images/exercises/gym-accessory.png");
    }
  };

  // Manejar el clic en el botón de detalle
  const handleDetailClick = () => {
    console.log("Ejercicio seleccionado:", exercise); // Paso 1: mostrar el ejercicio en consola
    setSelectedExercise(exercise); // Guardar el ejercicio en el contexto
    console.log("Ejercicio guardado en el contexto:", exercise); // Confirmar que se ha guardado
  };

  return (
    <div className="card workout-card">
      <img
        src={getImageForGroup(exercise.GrupoMuscular)}
        alt="Ejercicio"
        className="card-img-top workout-card-image"
      />
      <div className="card-body">
        <h5 className="card-title">{exercise.NombreEjercicio}</h5>
        <div className="workout-details">
          <p>
            <FaDumbbell /> Series: {exercise.Series}
          </p>
          <p>
            <FaRedo /> Repeticiones: {exercise.Repeticiones}
          </p>
          <p>
            <FaLayerGroup /> Grupo Muscular: {exercise.GrupoMuscular}
          </p>
        </div>
        <button
          className="btn btn-primary workout-detail-button"
          onClick={handleDetailClick}
        >
          <FaInfoCircle /> Detalle del ejercicio
        </button>
      </div>
    </div>
  );
};

export default SnackProfileUserWorkoutsCard;
