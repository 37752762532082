// Importaciones necesarias
import React, { useEffect, useState } from "react";
import { useConfig } from "../../../utensils/ConfigContext"; // Token de API
import { useUser } from "../../../utensils/UserContext"; // Contexto de usuario autenticado
import { useStatusBoard } from "../../../utensils/StatusBoardContext"; // Contexto de ingesta seleccionada
import axios from "axios";
import SnackProfileUserNutritionCard from "./profile-nutrition-card/SnackProfileUserNutritionCard"; // Componente de tarjeta de nutrición
import "./SnackProfileUserNutrition.css";

const SnackProfileUserNutrition = () => {
  const { apiToken } = useConfig(); // Obtener el token de API
  const { user } = useUser(); // Obtener el usuario autenticado
  const { selectedIngesta } = useStatusBoard(); // Obtener la ingesta seleccionada
  const [selectedPlan, setSelectedPlan] = useState("Plan A"); // Estado para el plan seleccionado
  const [nutritionPlan, setNutritionPlan] = useState([]); // Estado para almacenar el plan nutricional de la ingesta seleccionada

  // Efecto para obtener el plan nutricional del usuario autenticado
  useEffect(() => {
    const fetchNutritionPlan = async () => {
      console.log("Verificando usuario y token para solicitud de datos...");
      if (!user?.Email || !apiToken) {
        console.log("Email o token no encontrados, cancelando solicitud.");
        return;
      }

      const url = `https://api.baserow.io/api/database/rows/table/270566/?user_field_names=true&search=${user.Email}`;
      console.log("URL para obtener plan nutricional:", url);

      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Token ${apiToken}` },
        });
        console.log("Respuesta recibida:", response.data);

        // Filtrar los resultados para la ingesta y el plan seleccionados
        console.log(
          "Filtrando plan nutricional para la ingesta:",
          selectedIngesta,
          "y plan:",
          selectedPlan
        );
        const filteredNutrition = response.data.results.filter(
          (item) =>
            item.Ingesta === String(selectedIngesta) &&
            item.Plan === selectedPlan
        );

        console.log("Plan nutricional filtrado:", filteredNutrition);
        setNutritionPlan(filteredNutrition);
      } catch (error) {
        console.error("Error al obtener el plan nutricional:", error);
      }
    };

    fetchNutritionPlan();
  }, [user, apiToken, selectedIngesta, selectedPlan]);

  // Manejar la selección del plan
  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  // Obtener la primera observación disponible
  const firstObservation = nutritionPlan.length > 0 ? nutritionPlan[0].Observaciones : "No hay observaciones disponibles.";

  // Mostrar mensaje si no se encuentran resultados
  if (nutritionPlan.length === 0) {
    console.log(
      "No se encontraron resultados para la ingesta y plan seleccionados."
    );
    return (
      <>
        <p>
          No hay un plan nutricional disponible para la ingesta y plan
          seleccionados.
        </p>

        <div>
          <label htmlFor="planSelect">Selecciona un plan: </label>
          <select
            id="planSelect"
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            <option value="Plan A">Plan A</option>
            <option value="Plan B">Plan B</option>
          </select>
        </div>
      </>
    );
  }

  // Renderizado del componente
  console.log("Renderizando plan nutricional en pantalla...");
  return (
    <>
      <h4 className="mb-3">Plan Nutricional - Ingesta {selectedIngesta}</h4>
      <div>
        <label htmlFor="planSelect">Selecciona un plan: </label>
        <select
          id="planSelect"
          value={selectedPlan}
          onChange={handlePlanChange}
        >
          <option value="Plan A">Plan A</option>
          <option value="Plan B">Plan B</option>
        </select>
      </div>
      <div className="nutrition-plan-container">
        {nutritionPlan.map((item) => (
          <SnackProfileUserNutritionCard key={item.id} nutritionItem={item} />
        ))}
      </div>
      <div className="observation-card">
        <h4>Observaciones:</h4>
        <p>{firstObservation}</p>
      </div>
    </>
  );
};

export default SnackProfileUserNutrition;
